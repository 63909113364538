import React from "react"

import Layout from "../components/layout"

const TechnologyPage = () => {
  return (
    <Layout>
      <main role="main" className="bg-white">
        <div className="relative pen-cover">
          <div className="pv6 mt0 center mw9 ph3">
            <h2 className="relative z-1 dib white frank ttu fw4 f4 mv4 tracked-mega">
              Tecnologia robusta significa prodotto affidabile
            </h2>
            <h1 className="relative z-1 pb3 playfair f2 f1-m f1-l fw4 white">
              Abbiamo risolto la parte più difficile
              <br />
              così tu non devi preoccuparti
            </h1>
            <h2 className="relative z-1 dib white frank fw3 f2">
              Affidati ad una crittografia sicura per salvare i tuoi dati e
              documenti
            </h2>
          </div>
        </div>

        <div className="relative pa4 bg-blue-color business-cover">
          <div className="flex flex-wrap mw9 center">
            <h4
              className="relative z-1 db dn-ns center fw4 mv0 f1 playfair white"
              style={{ marginBottom: "1rem" }}
            >
              I tuoi dati sono al sicuro con noi
            </h4>
            <div className="w-100 w-50-ns">
              <img
                src="/images/gdpr.jpg"
                className="relative z-2 db center w-100 shadow-5 mt3 mt6-ns pt3-ns"
                style={{ filter: "grayscale(50%)" }}
                alt=""
              />
            </div>
            <div className="w-100 w-50-ns ph3 pb4-ns">
              <h4 className="relative z-1 dn db-ns fw4 mv0 f1 playfair white">
                I tuoi dati sono al sicuro con noi
              </h4>
              <div className="relative z-1 mv4 pv3 bb b--white">
                <h6 className="relative z-1 mv0 frank fw4 f3 ttu tracked white">
                  Crittografia forte
                </h6>
              </div>
              <p className="relative z-1 mv3 frank fw4 f4 measure lh-copy white">
                Tutti i dati sono criptati con una chiave segreta molto grande e
                quindi sicura. Nessun dato viene o verrà mai condiviso con altri
                soggetti.
              </p>
              <div className="relative z-1 mv4 pv3 bb b--white">
                <h6 className="relative z-1 mv0 frank fw4 f3 ttu tracked white">
                  A prova di privacy
                </h6>
              </div>
              <p className="relative z-1 mv3 frank fw4 f4 measure lh-copy white">
                Time2Client non ha accesso ai tuoi dati e a quelli del tuo
                cliente. Solo il legittimo avvocato è il responsabile nella
                gestione dei dati dei clienti.
              </p>
              <div className="relative z-1 mv4 pv3 bb b--white">
                <h6 className="relative z-1 mv0 frank fw4 f3 ttu tracked white">
                  Conforme alla GDPR
                </h6>
              </div>
              <p className="relative z-1 mv3 frank fw4 f4 measure lh-copy white">
                Pienamente conforme alla direttiva europea sulla privacy GDPR.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap-reverse mv6 mw8 center">
          <h4
            className="relative z-1 db dn-ns center fw4 mv0 f1 playfair"
            style={{ marginBottom: "1rem" }}
          >
            Il tuo tempo è prezioso
          </h4>
          <div className="w-100 w-60-ns ph3 pb4-ns">
            <h4 className="dn db-ns fw4 mv0 f1 playfair">
              Il tuo tempo è prezioso
            </h4>
            <div className="mv4 pv3 bb">
              <h6 className="mv0 frank fw4 f3 ttu tracked">
                Condividi con altri colleghi
              </h6>
            </div>
            <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
              Scegli con chi condividere una pratica, cartella o anche singolo
              documento.
            </p>
            <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
              Invita gli altri colleghi a caricare documenti nella tua pratica.
            </p>
            <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
              Grazie ad una visione globale dei permessi, hai sempre sott'occhio
              una panoramica di chi ha accesso a cosa.
            </p>
            <div className="mv4 pv3 bb">
              <h6 className="mv0 frank fw4 f3 ttu tracked">
                Notifiche mail intelligenti
              </h6>
            </div>
            <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
              Quando un avvocato aggiorna una pratica, una notifica
              intelligente* viene inviata al cliente, così sarà sempre informato
              su ogni progresso.
            </p>
            <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
              Ricevi una notifica intelligente* quando il tuo cliente o il
              collega a cui hai dato accesso ad una pratica carica dei nuovi
              documenti.
            </p>
            <div className="mv4 pv3 bb">
              <h6 className="mv0 frank fw4 f3 ttu tracked">
                La storia della pratica
              </h6>
            </div>
            <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
              Crea degli eventi per segnare tutti i passaggi della pratica ed
              esportali nel tuo calendario preferito (Google calendar, Outlook,
              oppure calendario Apple iOS / Android).
            </p>
            <p className="mv3 frank fw4 f5 measure lh-copy">
              <span className="f3 frank">* </span>Il sistema di notifiche mail
              intelligente aggrega più modifiche effettuate in serie in un'unica
              mail, così da non subissarti di futili notifiche.
            </p>
          </div>
          <div className="w-100 w-40-ns">
            <img
              src="/images/screen1.png"
              className="db mv4 center w-100 shadow-5"
              alt=""
            />
            <img
              src="/images/screen2.png"
              className="db mv4 center w-100 shadow-5"
              alt=""
            />
          </div>
        </div>

        <div
          className="relative pv5 mt7 bg-light-color footer-cover"
          style={{ backgroundColor: "#2b2928" }}
        >
          <div className="mw9 pb5 ph3 tc-ns center">
            <p className="white f4 f3-m f2-l mv0 pv0 playfair">
              Time2Client è in fase di anteprima e disponibile solo su invito.
            </p>
            <p className="white f4 f3-m f2-l mv0 pv0 playfair">
              Se vuoi essere tra i primi Clienti, inserisci il tuo indizzo mail
              qui sotto.
            </p>
          </div>
          <form
            action="/it/registration/interest"
            className="bg-sec-color white mw7 center pa4 br2-ns ba b--black-10"
            method="post"
          >
            <input
              name="_csrf_token"
              type="hidden"
              value="JAcyQiBqGxkkZHtrQAAPNlkuXTglAVkNKWGqpZ6zR5BXyPeR2vnpFYn5"
            />{" "}
            <fieldset className="cf bn ma0 pa0">
              <legend className="pa0 f5 f4-ns mb3">
                Voglio provare Time2Client
              </legend>
              <div className="cf">
                <label className="clip" htmlFor="lawyer_email">
                  Email
                </label>
                <input
                  className="f6 f5-l input-reset bn fl black-80 bg-white pa3 lh-solid w-100 w-75-m w-80-l br2-ns br--left-ns"
                  id="lawyer_email"
                  name="lawyer[email]"
                  placeholder="avvocato@studiolegale.it"
                  type="email"
                />
                <button
                  className="frank f5 f4-l button-reset fl pv3 tc bn bg-animate bg-black-70 hover-bg-prim-color white pointer w-100 w-25-m w-20-l br2-ns br--right-ns"
                  type="submit"
                >
                  Invia
                </button>{" "}
              </div>
              <p className="lato f5 pt3">Tienimi informato</p>
            </fieldset>
          </form>
        </div>
      </main>
    </Layout>
  )
}

export default TechnologyPage
